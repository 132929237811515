import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2023_07_13-Harnett-CALUCA-TownHall-webinar.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
		<StyledContainer>
			<WebinarBody
				viewAllowed={props.viewAllowed}
			>
				<div className="flexBox">
					<div className="block block--left">
						<div className="block__content">
							<h2 data-aos="fade-up">Overview</h2>
							<p data-aos="fade-up">
								The threat of SARS-CoV-2 has slowed considerably, STIs are back in full force -
								with a vengeance. Urgent care clinics are a likely go-to option, providing quality
								care in an efficient and convenient manner—and the added benefit of relative anonymity.
								Incorporating point-of-care PCR testing for STI can bring speed to diagnosis and STI
								management in Urgent Care settings.</p>
							<h2 data-aos="fade-up">Learning Objectives</h2>
							<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
							<ul data-aos="fade-up">
								<li>Review applicable CDC STI Treatment & Screening Guidelines</li>
								<li>Discuss sexual health education strategies for STIs in young people</li>
								<li>Examine the importance of timely STI diagnosis and appropriate treatment</li>
								<li>Discuss definitive/directed antibiotic therapy vs empiric/presumptive treatment</li>
								<li>Review the advantages of rapid, point-of-care STI testing in urgent care</li>
							</ul>
						</div>
					</div>

					<div className="block block--right">
						<div className={(props.pageName === 'display' | props.viewAllowed === false) ? "accessBox show" : "accessBox"}>
							<h3 data-aos="fade-up">Access Includes:</h3>
							<ul data-aos="fade-up">
								<li>Video recording replay</li>
								<li>Presentation Slides (PDF)</li>
							</ul>
						</div>
						<div className={(props.pageName === 'download') ? "downloadBox show showPDF" : "downloadBox"}>
							<h3 data-aos="fade-up">Download</h3>
							<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
						</div>
					</div>
				</div>

				<div className="block block--left">
					<div className="block__content presenter">
						<h2 data-aos="fade-up">About the Presenter</h2>

						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/harnett.jpg"
								width={200}
								alt="Dr. Glenn Harnett"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Dr. Harnett is the Principal for No Resistance Consulting Group, Trustee at 
							UCA Urgent Care Foundation, and on the Editorial Board Member at The Journal of Urgent Care Medicine.
							He has practiced for 20+ years as an emergency medicine and urgent care physician. He is an active 
							researcher and has been a Principal Investigator in dozens of clinical trials (both Sponsor-initiated 
							and Investigator-initiated). He focuses on original research in urgent care medicine.
							</p>
						</div>
					</div>
				</div>
			</WebinarBody>
		</StyledContainer>
	)
}

class FullBody extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			viewAllowed: false
		}
	}

	componentDidMount = props => {
		if((this.props.publicView) || (localStorage.getItem(this.props.localStorageID)) ) {
			this.setState({
				viewAllowed: true
			})
		}
	}

	render = () => (
		<BodyLanding
			pageName={this.props.pageName}
			viewAllowed={this.state.viewAllowed}
			localStorageID={this.props.localStorageID}
		/>
	)
}

export default FullBody
