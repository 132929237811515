import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2023-07-13-harnett/body-landing"

const HarnettView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Point-of-Care PCR Testing for STIs: Advancing Urgent Care Diagnostics"
				videoDisplayTitle="Point-of-Care PCR Testing for STIs: Advancing Urgent Care Diagnostics"
				vimeoLink="https://player.vimeo.com/video/862086229?h=470fe0caa4" 
				placeholderImage="2023-07-13-harnett-webinar-replay.jpg"
				localStorageID="2023-07-13-harnett-webinar-replay"
				pageSlug="/webinar-replay/2023-07-13-harnett/"
			/>
			<BodyLanding pageName="download" localStorageID="2023-07-13-harnett-webinar-replay" />
		</React.Fragment>
	)
}

export default HarnettView

export const Head = () => (
  <Seo
  title="Webinar: Point-of-Care PCR Testing for STIs: Advancing Urgent Care Diagnostics"
  description="Point-of-Care PCR Testing for STIs: Advancing Urgent Care Diagnostics"
  image="/meta/webinar-harnett-2023-07-13.jpg"
/>
)
